<template>
	<body>
		<video autoplay muted loop id="myVideo">
			<source class="myVideo" src="@/assets/images/pages/pexels-roman.mp4" type="video/mp4" />
			Your browser does not support HTML5 video.
		</video>
		<div>
			<div slot="no-body" class="contenido">
				<div class="form">
					<div class="row g-3 mt-3" id="login">
						<div class="vx-card__title mb-4">
							<img src="@/assets/images/pages/logo-soc-nuevo.png" class="margen-img" alt="login" width="200" />
							<h6 class="text-center mt-3 bienvenido">Bienvenido, por favor inicia sesión</h6>
						</div>

						<div>
							<div class="span-placeholder user">Correo electrónico</div>
							<input name="email" icon-no-border icon="icon icon-user" icon-pack="feather" v-model="email" class="vs-input" />
						</div>
						<div>
							<div class="span-placeholder password">Contraseña</div>
							<input type="password" name="password" icon-no-border icon="icon icon-lock" icon-pack="feather" v-model="password" class="vs-input" @keyup.enter="login()" />
						</div>
						<div class="recuperar alinear">
							<div class="col-md-6">
								<vs-checkbox v-model="checkbox_remember_me">Recuerdame</vs-checkbox>
							</div>
							<div class="olvidaste col-md-6">
								<router-link to="/recuperarcontrasena">¿Olvidaste la contraseña?</router-link>
							</div>
						</div>
						<!-- <vs-button  type="border">Registro</vs-button> -->
						<div class="row iniciar">
							<button type="button" class="btn btn-primary" @click="login()" :disabled="!validateForm">Iniciar Sesión</button>
						</div>

						<div class="span-placeholder user">Ingrese usando su cuenta en:</div>

						<div class="row iniciar">
							<button type="button" class="btn btn-success" @click="MultiloginSoc()">SOC</button>
						</div>
						<div class="row iniciar">
							<button type="button" class="btn btn-success" @click="MultiloginInmobiliario()">Inmobiliario</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</body>
</template>

<script>
import md5 from 'js-md5';
export default{
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
    }
  },
  computed: {
    validateForm() {
			return this.email || this.password
		}
  },
  methods:{
    MultiloginSoc(){
      window.location = 'https://cotizador.socasesores.com/Multilogin/AutenticaEjecutivo.php'
    },
    MultiloginInmobiliario(){
      window.location = 'https://cotizador.socasesores.com/Multilogin/AutenticaInmobiliario.php'
    },
    login(){
      let self=this
      this.$vs.loading({
        container: '#login',
        scale: 0.6
      })
      var objRequestAutentica = {
        strApiKey: 'a13768e0b29183037ac1f3acb68871bc',
        strMetodo: 'AutenticaEjecutivo',
        objEjecutivo: {
          EMail: this.email,
          Password: md5(this.password)
        }
      }
      this.$axios.post('/',objRequestAutentica,{headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
      .then(
        response => {

          const status = response.data.intEstatus

          if ( status ) {

            this.$vs.loading.close('#login > .con-vs-loading')
            return this.$vs.notify({
              title:'Ocurrio un error',
              text: response.data.strError,
              color:'danger',
              position:'top-right'
            })

          }

          const content = response.data.objContenido
          const widgetColorPrimario = content.WidgetColorPrimario
          const guid = content.SocGuid
          const tipoEjecutivoV3 = content.TipoEjecutivoV3
          const eMail = content.EMail

          widgetColorPrimario && this.$vs.theme({ primary: widgetColorPrimario })

          this.$vs.loading.close('#login > .con-vs-loading')

          // Usuario por Guid
          if ( guid ) {

            localStorage.removeItem('login')
            this.$router.push(`/?guid=${ guid }`).catch(() => {})

          }

          // Tipo Usuario Inmobiliario
          /*if ( tipoEjecutivoV3 === 'Inmo' ) {
              return this.$router.push(`/?guid=${ eMail }`).catch(() => {})
          }*/

          // Usuario General
          localStorage.setItem('userInfo',JSON.stringify(response.data.objContenido))
          this.$store.commit('UPDATE_USER_INFO',response.data.objContenido)
          localStorage.setItem('login', true)
          this.$router.push('/').catch(() => {})
        }
      ).catch(function (error) {
        self.$vs.notify({
          title:'Ocurrio un error de sistema 0',
          text:error,
          color:'danger',
          position:'top-right'
        })
      })
    },
  }
}
</script>

<style lang="scss">
.margen-img{
  margin-left: auto;
  margin-right: auto;
}
.login-form{
  margin-left: 0%
}
.card-login{
  background: none !important;
  box-shadow: none !important;
}
.btn-primary {
    padding: 0.5em;
    min-width: 270px !important;
    background-color: #006D4E !important;
    border-color: #006D4E !important;
    transition: 400ms;
}
.btn-primary:hover {
    padding: 0.5em;
    min-width: 270px !important;
    background-color: #4ED176 !important;
    border-color: #4ED176 !important;
}
.btn-success {
    padding: 0.5em;
    min-width: 270px !important;
    background-color: #079DEF  !important;
    border-color: #079DEF !important;
    transition: 400ms;
}
.btn-success:hover {
    padding: 0.5em;
    min-width: 270px !important;
    background-color: #079DEF !important;
    border-color: #079DEF !important;
}
.con-vs-checkbox{
  margin-left: -2px !important;
}
.vs-checkbox{
  width: 15px !important;
  height: 15px !important;
}
</style>
